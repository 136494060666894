button {
  margin: 0;
  padding: 0;
  margin: 0 auto;
  font-size: 16px;
}

.Button {
  padding: 8px;
  font-weight: bold;
  border-radius: 6px;
  background-color: var(--lightest);
  border: 1px solid var(--outline-color);
  color: var(--darkest);
  text-align: center;
  cursor: pointer;
  user-select: none;
  transition: all 20ms linear;
  transform: scale(1);
}

.Button:active {
  transform: scale(0.98);
}

@media(min-width: 601px) {
  .Button:hover, Button:focus {
    filter: brightness(85%);
  }
}

.Button.Action {
  background-color: var(--lightest);
  color: var(--black);
}

.Button.Danger {
  background-color: var(--button-danger-bg-color);
  color: var(--button-danger-fg-color);
}

.Button:active {
  -webkit-box-shadow: inset 0px 0px 5px #c1c1c1;
  -moz-box-shadow: inset 0px 0px 5px #c1c1c1;
  box-shadow: inset 0px 0px 5px #c1c1c1;
  outline: none;
}

.Button.disabled,
.Button:hover.disabled,
.Button:active.disabled {
  transform: scale(1);
  filter: brightness(100%);
  cursor: not-allowed;
}