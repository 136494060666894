:root {
  --gray-lightest: #eee;
  --gray-light: #bbb;
  --gray-neutral: #888;
  --gray-dark: #444;
  --gray-darkest: #111;

  --gold-lightest: #E9DAC2;
  --gold-light: #C0AC8B;
  --gold-neutral: #9E8354;
  --gold-dark: #775C23;
  --gold-darkest: #533E19;

  --blue-white: #F9F9FF;
  --blue-lightest: #d5dbe6;
  --blue-light: #4B5F7C;
  --blue-neutral: #274168;
  --blue-dark: #0D2444;
  --blue-darkest: #061529;
  --blue-black: #223;

  --red-light: #942828;
}

:root.gray-theme {
  --lightest: var(--gray-lightest);
  --light: var(--gray-lighter);
  --neutral: var(--gray-neutral);
  --dark: var(--gray-dark);
  --darkest: var(--gray-darkest);
}

:root.gold-theme {
  --lightest: var(--gold-lightest);
  --light: var(--gold-light);
  --neutral: var(--gold-neutral);
  --dark: var(--gold-dark);
  --darkest: var(--gold-darkest);
}

:root.blue-theme {
  --white: var(--blue-white);
  --lightest: var(--blue-lightest);
  --light: var(--blue-light);
  --neutral: var(--blue-neutral);
  --dark: var(--blue-dark);
  --darkest: var(--blue-darkest);
  --black: var(--blue-black);
}

body {
  color: var(--darkest);
  background-color: white;
}