body {
  --nav-zindex: 99;
  --modal-zindex: 100;

  --page-fg-color: #222;
  --page-bg-color: #eee; 
  --top-nav-height: 40px;
  --side-nav-width: 192px;
  --side-nav-button-fg-color: #222;
  --side-nav-button-bg-color: #fff;
  --mobile-bottom-nav-height: 200px;

  --light-gray: #eee;
  --gray: #999;
  --outline-color: rgba(0, 0, 0, 0.2);

  --button-action-fg-color: var(--white);
  --button-action-bg-color: var(--darkest);

  --button-neutral-fg-color: var(--black);
  --button-neutral-bg-color: var(--lightest);

  --button-danger-bg-color: red;
  --button-danger-fg-color: white;

  --form-error-fg-color: #cc0000;
  --form-error-bg-color: pink;

  --success-bg-color: #cce5ff;
  --success-fg-color: var(--darkest);
}