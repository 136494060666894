*:focus {
  outline: 0 !important;
}

html, body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  height: 100%;
  width: 100%;
  -moz-user-select: none;
  -webkit-user-select: none;
}

body {
  position: relative;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: none;
}

ul {
  list-style-type: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a, a:visited, a:link, a:active {
  text-decoration: none;
  color: inherit;
}